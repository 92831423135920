import React from 'react'
import css from './Hero.module.css'
import HeroImg from '../../assets/hero.png'
import {RiShoppingBagFill} from 'react-icons/ri'
import {BsArrowRight} from 'react-icons/bs'
import {motion} from 'framer-motion'

const Hero = () => {
    const transition={duration: 3,type: 'spring'}
  return (
    <div className={css.container}>
        {/* Left Side*/}
        <div className={css.h_sides}>
            <span className={css.text1}>Skin Protection Cream</span>
            
            <div className={css.text2}>
                <span>Trendy Collections</span>
                <span>
                {""}
                Seedily Say Has Suitable Disposal and Boy. Exercise joy Man Children Rejoiced</span>
            </div>
        </div>
        {/* Middle Side Hero Images */}
        <div className={css.wrapper}>
            <motion.div 
            initial={{bottom:'2rem'}}
            whileInView={{bottom:'0rem'}}
            className={css.blueCircle}
            transition={transition}
            ></motion.div>
                <motion.img 
                transition={transition}
                initial={{bottom:'-2rem'}}
                whileInView={{bottom:'0rem'}}
                src={HeroImg} alt='' width={600}/>
                
                {/* cart div animating */}
                <motion.div 
                transition={transition}
                initial={{right:'4%'}}
                whileInView={{right:'2%'}}
                className={css.cart2}>
                    <RiShoppingBagFill/>
                    <div className={css.signup}>
                        <span>Best SignUp Offers</span>
                        <div>
                            <BsArrowRight/>
                        </div>
                    </div>
              </motion.div>
        </div>
        {/* Right Sides */}
        <div className={css.h_sides}>
            <div className={css.traffic}>
                <span>1.5M</span>
                <span>Monthly Traffic</span>
            </div>
            <div className={css.customers}>
                <span>100K</span>
                <span>Happy Customers</span>
            </div>
        </div>
    </div>
  )
}

export default Hero